import React, { useEffect, useState } from "react";
import desktop from "../Assets/IMG/his/Desktop.png";
import med_icon from "../Assets/IMG/his/Vector.png";
import logo from "../Assets/IMG/his/logo.png";
import checkup from "../Assets/IMG/his/checkup.png";
import Tick from "../Assets/IMG/his/Tick.png";
import Tech from "../Assets/IMG/his/Tech.png";
import chart1 from "../Assets/IMG/his/chart1.png";
import chart2 from "../Assets/IMG/his/chart2.png";
import graph from "../Assets/IMG/his/graph.png";
import prescription from "../Assets/IMG/his/prescription.png";
import patientVisit from "../Assets/IMG/his/PatientVisit.png";
import emr from "../Assets/IMG/his/EMR.png";
import med_illustration from "../Assets/IMG/his/med_illustration.png";
import appointment1 from "../Assets/IMG/his/appointment1.png";
import appointment2 from "../Assets/IMG/his/appointment2.png";
import appointment3 from "../Assets/IMG/his/appointment3.png";
import health_tech from "../Assets/IMG/his/health_tech.png";
import error_log from "../Assets/IMG/his/ErrorLog.png";
import audit_trail from "../Assets/IMG/his/AuditTrail.png";
import consultation from "../Assets/IMG/his/Consultation.png";
import mobile_home from "../Assets/IMG/his/mobile_home.png";
import easy_book from "../Assets/IMG/his/easy_booking.png";
import appointment_search from "../Assets/IMG/his/appointment_search.png";
import squbix_footer from "../Assets/IMG/his/squbix_footer.png";
import up from "../Assets/IMG/up-arrow.png";
import down from "../Assets/IMG/down-arrow.png";
import "../Css/his.css";

//* HIS Product Page
//* ------------------

const HIS = ({ setNav }) => {
  useEffect(() => {
    setNav("products");
  });

  //* Scrroling Top and Bottom
  //* ---------------------------

  const [atBottom, setAtBottom] = useState(false);

  useEffect(() => {
    //* Scroll to top on page refresh
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const isAtBottom =
        window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight - 1;
      setAtBottom(isAtBottom);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleLogoClick = () => {
    const scrollTarget = atBottom ? 0 : document.documentElement.scrollHeight;
    window.scrollTo({ top: scrollTarget, behavior: "smooth" });
  };

  //* Offerings Sections Data
  //* -------------------------

  const Offerings = [
    "Easy Doctor appointment management",
    "Real-time data of patients",
    "Negligible paperwork",
    "Negligible manual Input",
    "Billing Management System",
    "Instant access to patient history",
    "Instant access to admin tools",
    "Multi-tenant customization",
    "Data safely stored on Cloud",
    "Dashboard for easy data visualization",
    "A.I. integrated tech for web & mobile",
  ];

  //* Presentations Sections Data
  //* ----------------------------

  const Presentations = [
    {
      heading: "Electronic Medical Records",
      subheading:
        "Centralized access and overview of patient's complete medical information.",
      img: [{ img1: chart1 }, { img2: chart2 }],
    },
    {
      heading: "Vitals Graphs of Patients",
      subheading: "Real-time monitoring of patient's physiological parameters.",
      img: [{ img1: graph }],
    },
    {
      heading: "Prescription",
      subheading:
        "Electronic generation and management of medical prescriptions.",
      img: [{ img1: prescription }],
    },
    {
      heading: "Patient Visit",
      subheading:
        "Comprehensive record of patient's medical background and treatments.",
      img: [{ img1: patientVisit }],
    },
    {
      heading: "EMR Dashboard",
      subheading:
        "The main page in the EMR system where the doctor can see all the important information in one place.",
      img: [{ img1: emr }],
    },
    {
      heading: "Medical Illustration",
      subheading:
        "3D visualization of patient anatomy for diagnostic and treatment planning.",
      img: [{ img1: med_illustration }],
    },
  ];

  //* Appointments Sections Data
  //* ----------------------------

  const Appointments = [
    { stat: "01 Daily Appointment Booking", img: appointment1 },
    { stat: "02 Weekly Appointment Booking", img: appointment2 },
    { stat: "03 Monthly Appointment Booking", img: appointment3 },
  ];

  //* Health Technical Sections Data
  //* ------------------------------

  const Health_Tech = [
    {
      heading: "Error Log",
      subheading: "Tracking and analysis of system errors for improvement.",
      img: error_log,
    },
    {
      heading: "Audit Trail",
      subheading:
        "Record of system activities for accountability and compliance.",
      img: audit_trail,
    },
  ];

  //* Mobile Sections Data
  //* ---------------------

  const Mobile_Views = [
    {
      heading: "Home Page",
      subheading:
        "Centralized access point to patient information, appointments, and notifications.",
      img: mobile_home,
    },
    {
      heading: "Appointment Search",
      subheading:
        "Patient-centric system for scheduling appointments with healthcare providers.",
      img: appointment_search,
    },
    {
      heading: "Easy Booking",
      subheading:
        "Simplified process for patients to book appointments with minimal steps.",
      img: easy_book,
    },
  ];

  //* Return the HIS UI for Render
  //* ----------------------------

  return (
    <>
      <div className="His_Overview">
        <div className="Overview_section">
          <div className="Description">
            <img src={med_icon} className="Med_icon" alt="Med_icon" />
            <span className="HeadText">Hospital Information System</span>
            <span className="sub_text">
              The <b className="sub_bold">&nbsp;Future&nbsp;</b>Of Healthcare
              Management
            </span>
          </div>
          <div className="Images">
            <img className="Desktop_image" src={desktop} alt="Desktop" />
          </div>

          <div className="logo_section">
            <img src={logo} alt="logo" />
          </div>
        </div>
        <div className="Offerings_Section">
          <div className="Deatils_Section">
            <div className="Text_Section">
              <div className="upper">
                <h2 className="H_Text">Offerings</h2>
              </div>
              <div className="lower">
                {Offerings.map((text, index) => (
                  <div key={index} className="check_Section">
                    <img className="Tick_Image" src={Tick} alt="Tick" />
                    <span className="sub_offer_text">{text}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="Image_Section">
            <img className="checkup_Image" src={checkup} alt="checkup" />
          </div>
        </div>
        <div className="Tech_Section">
          <img className="Tech_Image" src={Tech} alt="Tech" />
          <span className="TechText">Technology for Web & Tablets</span>
        </div>
        <div className="Medical_Section">
          {Presentations.map((view, index1) => (
            <div key={index1}>
              <div className="Med_Text_Section">
                <span className="Med_Text_Head">{view.heading}</span>
                <span className="Med_Text_Sub">{view.subheading}</span>
              </div>
              {view.img.map((image, index) => (
                <div key={index} className="Med_Image_Section">
                  <img
                    key={index}
                    className="chart"
                    src={Object.values(image)[0]}
                    alt="chart"
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="Medical_Section">
          <div className="Med_Text_Section">
            <span className="Med_Text_Head">Appointments</span>
            <span className="Med_Text_Sub">
              Efficient scheduling and management of patient appointments.
            </span>
          </div>
          {Appointments.map((Stats, index) => (
            <div key={index}>
              <div className="Med_Text_Section">
                <span className="Med_Text_Sub">{Stats.stat}</span>
              </div>
              <div className="Med_Image_Section">
                <img
                  key={index}
                  className="chart"
                  src={Stats.img}
                  alt="chart"
                />
              </div>
            </div>
          ))}
        </div>
        <div className="Tech_Section">
          <img className="Tech_Image" src={health_tech} alt="Tech" />
          <span className="TechText">Healthcare Technical Analysis</span>
        </div>
        <div className="Medical_Section">
          {Health_Tech.map((data, index) => (
            <div key={index}>
              <div className="Med_Text_Section">
                <span className="Med_Text_Head">{data.heading}</span>
                <span className="Med_Text_Sub">{data.subheading}</span>
              </div>
              <div className="Med_Image_Section">
                <img className="chart" src={data.img} alt="chart" />
              </div>
            </div>
          ))}
        </div>
        <div className="Tech_Section">
          <img className="Tech_Image" src={consultation} alt="Tech" />
          <span className="TechText">Easy Consultation</span>
        </div>
        <div className="Medical_Section">
          {Mobile_Views.map((data, index) => (
            <div key={index}>
              <div className="Med_Text_Section">
                <span className="Med_Text_Head">{data.heading}</span>
                <span className="Med_Text_Sub">{data.subheading}</span>
              </div>
              <div className="Med_Image_Section">
                <img className="chart" src={data.img} alt="chart" />
              </div>
            </div>
          ))}
        </div>
        <div className="Medical_Section_footer">
          <img className="footerImage" src={squbix_footer} alt="squbix" />
          <span className="footerText">
            Address- DCB 926, DLF Cyber City, Infocity, Patia, Bhubaneswar,
            India - 751024
          </span>
          <a className="footerText" href="https://squbix.com/">
            <b>squbix.com</b>
          </a>
        </div>
        <div className="scrollIcon" onClick={handleLogoClick}>
          {atBottom ? (
            <img className="scroll_IconTop icon" src={up} alt="Go to Top" />
          ) : (
            <img
              className="scroll_IconDown icon"
              src={down}
              alt="Go to Bottom"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default HIS;
