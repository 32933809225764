import React, { useEffect, useState } from "react";
import "./Css/ContactUs.css";
import { IoMdMail } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";
import Aos from "aos";
import "aos/dist/aos.css";
import Footer from "../Footer/Footer";
const ContactUs = ({ setNav }) => {
    const [selectedIndex, setselectedIndex] = useState(0);
    const [message, setMessage] = useState("");
    const [subject, setSubject] = useState("");

    useEffect(() => {
        setNav("contactus");
        setMessage("");
        setSubject("");
        Aos.init({
            disable: window.innerWidth < 700,
        });
    }, []);

    useEffect(() => {
        setMessage("");
        setSubject("");
    }, [selectedIndex]);

    const data = [
        {
            email: "info@squbix.com",
            MapDetails:
                "926 DLF Cybercity , Patia , Chandrasekharpur , Bhubaneswar , India - 751024",
            MapLink: "https://maps.app.goo.gl/KbYDWKbbWQSYbAA16",
            index: 0,
            location: "https://maps.app.goo.gl/BZ6LmWsrGr2NqGdM9",
        },
        {
            email: "dubai@squbix.com",
            MapDetails:
                "Residence 1072 , Office-18B , AI Muteena , Plot No-346-O Dubai Mainland , UAE ",
            MapLink: "https://maps.app.goo.gl/tpuhk4sjaNPquM3P6",
            index: 1,
        },
        {
            email: "singapore@squbix.com",
            MapDetails:
                "11 Collyer Quay. #11-06 The Arcade, Singapore - 049317, Singapore",
            MapLink: "https://maps.app.goo.gl/eUt85HM4gZAE5mZT8",
            index: 2,
        },
        // {
        //     email: "sydney@squbix.com",
        //     MapDetails:
        //         "Sydney U2 23 Motros Street , Quakers Hill , NSW 2763 , Sydney , Australlia",
        //     MapLink: "https://maps.app.goo.gl/PGsp6rAUyesh79bV7",
        //     index: 3,
        // },
        {
            email: "usa@squbix.com",
            MapDetails: "2 Budd Cir, Shelton, CT,06484, USA",
            MapLink: "https://maps.app.goo.gl/KWgreJTTzvG3brRS9",
            index: 3,
        },
    ];
    const openGoogleMaps = () => {
        const googleMapsUrl = data[selectedIndex]?.MapLink;
        window.open(googleMapsUrl, "_blank");
    };

    return (
        <div
            style={{
                width: "100vw",
                overflow: "hidden",
                paddingTop: "20px",
            }}
        >
            <div className="data_container">
                <div className="contact-bg1"></div>
                <div data-aos="fade-up" className="contact-container">
                    <div className="tab_container">
                        <div className="desktop_Container">
                            <div></div>
                            <div className="All-btn">
                                <div
                                    className={`IndiaBtn ${
                                        selectedIndex === 0
                                            ? "All-btn-active"
                                            : ""
                                    }`}
                                    id="btn"
                                    style={
                                        selectedIndex === 1
                                            ? { borderRight: "none" }
                                            : {}
                                    }
                                    onClick={() => setselectedIndex(0)}
                                >
                                    India
                                </div>

                                <div
                                    className={`dubaiBtn ${
                                        selectedIndex === 1
                                            ? "All-btn-active"
                                            : ""
                                    }`}
                                    id="btn"
                                    style={
                                        selectedIndex === 2
                                            ? { borderRight: "none" }
                                            : {}
                                    }
                                    onClick={() => setselectedIndex(1)}
                                >
                                    Dubai
                                </div>

                                <div
                                    className={`SingapurBtn ${
                                        selectedIndex === 2
                                            ? "All-btn-active"
                                            : ""
                                    }`}
                                    id="btn"
                                    style={
                                        selectedIndex === 3
                                            ? { borderRight: "none" }
                                            : {}
                                    }
                                    onClick={() => setselectedIndex(2)}
                                >
                                    Singapore
                                </div>
                                {/* <div
                                    className={`AustraliaBtn ${
                                        selectedIndex === 3
                                            ? "All-btn-active"
                                            : ""
                                    }`}
                                    id="btn"
                                    style={
                                        selectedIndex === 4
                                            ? { borderRight: "none" }
                                            : {}
                                    }
                                    onClick={() => setselectedIndex(3)}
                                >
                                    Sydney
                                </div> */}
                                <div
                                    className={`usaBtn ${
                                        selectedIndex === 3
                                            ? "All-btn-active"
                                            : ""
                                    }`}
                                    id="btn"
                                    onClick={() => setselectedIndex(3)}
                                >
                                    USA
                                </div>
                            </div>

                            <div className="contact-details">
                                <div className="call_details">
                                    <div
                                        className="icon"
                                        onClick={() =>
                                            window.open(
                                                `mailto:${data[selectedIndex]?.email}`
                                            )
                                        }
                                        style={{ cursor: "pointer" }}
                                    >
                                        <IoMdMail
                                            size={25}
                                            className="contact-icon"
                                        />
                                    </div>
                                    <span className="email_place">
                                        {
                                            data?.find(
                                                (v) =>
                                                    v?.index ===
                                                    Number(selectedIndex)
                                            )?.email
                                        }
                                    </span>
                                </div>
                                <div className="map-details">
                                    <div
                                        className="icon"
                                        onClick={openGoogleMaps}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <FaLocationDot
                                            size={25}
                                            className="contact-icon"
                                        />
                                    </div>
                                    <span>
                                        {
                                            data?.find(
                                                (v) =>
                                                    v?.index ===
                                                    Number(selectedIndex)
                                            )?.MapDetails
                                        }
                                    </span>
                                </div>
                            </div>

                            <div className="user-details">
                                <input
                                    type="email"
                                    name="email"
                                    id="dtlname"
                                    placeholder="Email Address"
                                />
                                <input
                                    type="text"
                                    name="subject"
                                    id="dtlname"
                                    placeholder="Subject"
                                    onChange={(e) => setSubject(e.target.value)}
                                />
                                <textarea
                                    id="dtlname"
                                    placeholder="Message"
                                    rows="5"
                                    onChange={(e) => setMessage(e.target.value)}
                                />
                                <div className="submit">
                                    <button>
                                        <a
                                            target="_top"
                                            href={`mailto:${data[selectedIndex]?.email}?subject=${subject}&body=${message}`}
                                            style={{
                                                textDecoration: "none",
                                                color: "white",
                                            }}
                                        >
                                            {" "}
                                            Send message
                                        </a>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ContactUs;
